.panelWrapper {
  display: flex;
  overflow: hidden;
  margin-top: 4px;
  flex-direction: column;
  max-height: 400px;
  min-height: 150px;
  background-color: var(--neutralWhite100);
  border-radius: 4px 4px 0px 0px;
  padding-top: 0px !important;
}

.panelHeader {
  background: var(--neutralBlack100);
  border-radius: 4px 4px 0px 0px;
}

.thinHeader {
  height: 42px;
}

.wideHeader {
  height: 56px;
}

.groupHeaderAttributes {
  height: 34px;
  max-width: 80%;
  overflow: hidden;
}

.groupHeaderSignature {
  min-width: 120px;
}

.groupElementsTableWrapper {
  display: flex;
  flex: 1;
  max-height: 324px;
  overflow: scroll;
}

.alertIcon {
  border-radius: 20px;
  border: 1px solid;
  padding: 1px;
}

.panelAttributes {
  overflow-x: scroll !important;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.panelAttributes::-webkit-scrollbar {
  display: none;
}

.declinedNote {
  border: 2px solid var(--danger400);
  padding: 8px;
}

.skullUrgent {
  background: var(--danger50);
}

.flameUrgent {
  background: var(--orange50);
}
