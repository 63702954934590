.subtoolbar {
  height: 52px;
}

.search {
  min-width: 320px;
}
.marker {
  border-radius: 4px;
  height: 26px;
  width: 4px;
}

.ticketIcon {
  border-radius: 20px;
  border: 1px solid;
  padding: 1px;
}

.ticketSignature {
  width: 160px;
}

.dashedLabelWrapper {
  position: relative;
  width: 100%;
}

.dashedLabelBorder {
  border: 1px dashed var(--neutralBlack24);
  width: 100%;
}

.dashedLabelText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #edf1f3;
}

.columnSearch {
  height: 26px !important;
  max-width: 200px;
  width: 100%;
}
